import { NAMESPACES } from 'constants/locales'

import React, { memo, useCallback } from 'react'
import Headline from '@typeform/ginger/dist/components/typography/headline/v2'
import { fontSize } from '@typeform/ginger/dist/constants/typography'
import AnimatedContainer from 'components/atoms/animated-container'
import useTranslation from 'next-translate/useTranslation'
import { getStaticAsset } from 'utils/assets'
import { routes } from 'utils/routes'
import { trackRedirectToPage } from 'components/tracking'

import { slideAndFade } from '../common/animations'
import TrackingLocation, {
  TRACKING_LOCATIONS,
} from '../common/tracking-location'

import {
  CtaContainer,
  Logo,
  IntegrationsListItem,
  IntegrationsList,
  Button,
  IntegrationsListContainer,
  IntegrationsContainer,
} from './integrations-section.styles'

const ASSETS_PATH = '/homepage/integrations'

const integrations = [
  {
    name: 'Zapier',
    logo: getStaticAsset(`${ASSETS_PATH}/zapier-logo.svg`),
  },
  {
    name: 'Calendly',
    logo: getStaticAsset(`${ASSETS_PATH}/calendly-logo.svg`),
  },
  {
    name: 'Hubspot',
    logo: getStaticAsset(`${ASSETS_PATH}/hubspot-logo.svg`),
  },
  {
    name: 'Slack',
    logo: getStaticAsset(`${ASSETS_PATH}/slack-logo.svg`),
  },
  {
    name: 'Notion',
    logo: getStaticAsset(`${ASSETS_PATH}/notion-logo.svg`),
  },
]

const IntegrationsSection = () => {
  const { t } = useTranslation(NAMESPACES.HOME)

  const handleBrowseIntegrationsClick = useCallback(() => {
    trackRedirectToPage({
      label: t('homepage.integrations.cta.label'),
      linkUrl: routes().connect.path,
      location: TRACKING_LOCATIONS.INTEGRATIONS,
    })
  }, [t])

  return (
    <TrackingLocation name={TRACKING_LOCATIONS.INTEGRATIONS}>
      <AnimatedContainer
        entryAnimations={slideAndFade}
        entryConfig={{ threshold: 0.1 }}
      >
        <IntegrationsContainer data-testid='integrations-section'>
          <CtaContainer>
            <Headline
              as='h2'
              largeViewportsFontSize={fontSize.xLarge}
              mediumViewportsFontSize={fontSize.large}
              smallViewportsFontSize={fontSize.large}
            >
              {t('homepage.integrations.title')}
            </Headline>
            <Button
              href={routes().connect.path}
              onClick={handleBrowseIntegrationsClick}
            >
              {t('homepage.integrations.cta.label')}
            </Button>
          </CtaContainer>
          <IntegrationsListContainer>
            <IntegrationsList>
              {integrations.map(({ name, logo }) => (
                <IntegrationsListItem key={name}>
                  <Logo
                    alt={name}
                    src={logo}
                    loading='lazy'
                    decoding='async'
                    fetchpriority='low'
                  />
                </IntegrationsListItem>
              ))}
            </IntegrationsList>
          </IntegrationsListContainer>
        </IntegrationsContainer>
      </AnimatedContainer>
    </TrackingLocation>
  )
}

IntegrationsSection.displayName = 'IntegrationsSection'

export default memo(IntegrationsSection)

import styled from 'styled-components'
import { BREAKPOINTS } from '@typeform/ginger/dist/constants/design'
import {
  fontSize,
  fontWeight,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import mediaqueries from '@typeform/ginger/dist/util/mediaqueries'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'

import SectionContainer from '../common/section-container'

export const IntegrationsContainer = styled(SectionContainer)`
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    padding-left: 0;
    padding-right: 0;
  }
`

export const CtaContainer = styled.div`
  grid-column: 1 / -1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 56px;
  text-align: center;
  padding-left: 32px;
  padding-right: 32px;

  ${mediaqueries.sm`
    margin-bottom: 32px;
  `}

  ${mediaqueries.md`
    padding-left: 0;
    padding-right: 0;
  `}

  ${mediaqueries.md`
    text-align: left;
    grid-column: 1 / 4;
    align-items: flex-start;
    margin-bottom: 0;
  `}
`

export const Button = styled.a`
  margin-top: 16px;
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.small};
  line-height: ${lineHeight.large};
  text-decoration: underline;
  color: ${NeutralColors.Ink};

  ${mediaqueries.md`
    margin-top: 32px;
  `}
`
export const IntegrationsListContainer = styled.div`
  grid-column: 1 / -1;
  ${mediaqueries.md`
    grid-column: -1 / 4;
  `}
`

export const IntegrationsList = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  gap: 24px;
  padding: 0;
  margin: 0;
  list-style: none;
  padding-left: 32px;
  padding-right: 32px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  ${mediaqueries.md`
    padding-left: 0;
    padding-right: 0;
  `}

  ${mediaqueries.md`
    overflow: visible;
  `}

  ${mediaqueries.lg`
    gap: 32px;
  `}
`

export const IntegrationsListItem = styled.li`
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  background-color: ${NeutralColors.GreyPaper};
  border-radius: 52px;
  padding: 24px;
  width: 184px;
  height: 184px;
  scroll-snap-align: center;

  ${mediaqueries.md`
    &:nth-of-type(1n + 4) {
      display: none;
    }
  `}

  ${mediaqueries.md`
    padding: 32px;
    width: 216px;
    height: 216px;
  `}

  ${mediaqueries.lg`
    width: 256px;
    height: 256px;
  `}
`

export const Logo = styled.img`
  width: 100%;
  height: auto;
`
